import React, { useState, useEffect, useContext } from "react";
import { ReviewContext } from "../../Contexts/ReviewContext"; // Import ReviewContext

const ReviewSlider = () => {
    const { reviews } = useContext(ReviewContext); // Use reviews from context
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < reviews.length - 1 ? prevIndex + 1 : 0
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : reviews.length - 1
        );
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000);

        // Clear the interval on component unmount
        return () => clearInterval(interval);
    }, [nextSlide]);

    return (
        <div className="relative w-full max-w-screen-xl mx-auto h-full mt-4">
            {/* Left Arrow */}
            <button
                onClick={prevSlide}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black text-white p-2 rounded-full"
            >
            <span className="text-[30px]">&lt;</span>
            </button>

            {/* Review Slider */}
            <div className="overflow-hidden h-full mx-8">
                <div
                    className="flex transition-transform duration-300 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {reviews.map((review) => (
                        <div
                            key={review.id}
                            className="flex-none w-full p-4 flex flex-col items-center justify-center space-y-4"
                        >
                            <div className="grid grid-cols-1 space-x-4 items-center text-center w-64">
                                <div className="flex flex-col items-center space-y-1.5">
                                    <div className="flex justify-center">
                                        {/* Render stars based on the `rating` property */}
                                        {Array(review.rating).fill().map((_, index) => (
                                            <svg
                                                key={index}
                                                className="w-6 h-6 text-green-400 me-1"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 22 20"
                                            >
                                                <path
                                                    d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
                                                />
                                            </svg>
                                        ))}
                                    </div>
                                    <h3 className="text-[16px] font-bold text-white">
                                        {review.title}
                                    </h3>
                                    <p className="text-[16px] font-normal text-white">{review.content}</p>
                                    <p className="text-[14px] text-[#4a4a4a] mt-1">{review.customerName}</p>
                                    <img
                                        src={review.img}
                                        alt={review.customerName}
                                        className="w-28 h-28 object-cover rounded-full"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Right Arrow */}
            <button
                onClick={nextSlide}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black text-white p-2 rounded-full"
            >
            <span className="text-[30px]">&gt;</span>
            </button>
        </div>
    );
};

export default ReviewSlider;
