import React, {useContext, useEffect, useState} from 'react';
import OrderTracking from "../Blocks/Product/OrderTracking";
import Star from "../Reviews/Star";
import ReviewWidget from "../Reviews/ReviewWidget";
import ReactCountryFlag from "react-country-flag"
import {ReviewContext} from "../../Contexts/ReviewContext";
import {useCart} from "../../Contexts/CartContext";
import {loadStripe} from "@stripe/stripe-js";
import {Elements, ExpressCheckoutElement} from "@stripe/react-stripe-js";
import {useSwipeable} from "react-swipeable";
import BenefitsBar from "../Blocks/BenefitsBar";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import getUserLocale from "get-user-locale";
import {Helmet} from "react-helmet";


function ProductPage({title, price, variants, rating, desc, img, images, description, stock, handle}) {
    const reviewContext = useContext(ReviewContext);
    const reviews = reviewContext.getReviewsByProductHandle(handle);
    const [quantity, setQuantity] = useState(1);

    const {addToCart} = useCart();

    const handleAddToCart = () => {
        addToCart({
            title,
            price: selectedPrice, // Use the currently selected price
            img: selectedImage,   // Use the currently selected image
            quantity,
            handle: handle,
            stock: selectedStock,
            selectedOptions,      // Include selected options
        });
    };


    // Calculate the average rating with two decimal places
    const averageRating = reviews.length
        ? (reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length).toFixed(2)
        : "0.00";

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        // Set the document title to product name + ResellerHeaven
        document.title = `${title} - ResellerHeaven`;

        // Clean up (optional)
        return () => {
            document.title = "ResellerHeaven"; // Reset to default when the component unmounts
        };
    }, [title]); // Depend on title to update the title when the product changes


    // State to track the selected image and options
    const [selectedOptions, setSelectedOptions] = useState(
        variants.reduce((acc, variant) => {
            acc[variant.variantName] = variant.variantOptions[0]?.name || ''; // Default to first option if exists
            return acc;
        }, {})
    );

    // State for currently displayed image
    const [selectedImage, setSelectedImage] = useState(img);
    const [selectedPreviewImage, setSelectedPreviewImage] = useState(img);
    const [selectedPrice, setSelectedPrice] = useState(price);
    const [selectedPriceFromatted, setSelectedPriceFromatted] = useState(new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(price));
    const [selectedStock, setStock] = useState(stock);


    const [selectedUrl, setUrl] = useState(null);

    const appearance = {};
    const [clientSecret, setClientSecret] = useState();
    const stripePromise = loadStripe("pk_live_51Ozc6fFqQPKh8TUqUUqKnd5DnZdakxxj67nxBO8ImV1V5fzZWARjGMr0xOrnXYIJn1inum5JGRasaahjtat8j9gJ00z7sBXc7z");

    const [payButton, setPayButton] = useState(null);
    const lang = getUserLocale();

    function stripHtml(html) {
        const div = document.createElement("div");
        div.innerHTML = html;
        return div.textContent || div.innerText || "";
    }


    // Structured Data for SEO
    const structuredData = {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: title,
        image: selectedImage,
        description: stripHtml(desc),
        sku: handle,
        brand: {
            "@type": "Brand",
            name: "Apple",
        },
        offers: {
            "@type": "Offer",
            priceCurrency: lang.includes("cs") ? "CZK" : "EUR",
            price: selectedPrice.toFixed(2),
            availability: stock > 0 ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
            url: window.location.href,
        },
        aggregateRating: {
            "@type": "AggregateRating",
            ratingValue: averageRating,
            reviewCount: reviews.length,
        },
    };



    useEffect(() => {
        const updatePaymentIntent = async () => {
            try {
                let finalPrice = lang.includes("cs") ? (selectedPrice * quantity) : (selectedPrice * quantity) / 25;
                let currency = lang.includes("cs") ? "czk" : "eur";

                const response = await fetch("https://api.resellerheaven.eu/create-or-update-payment-intent2/" + currency, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({totalAmount: finalPrice}),
                });
                const data = await response.json();
                setClientSecret(data.clientSecret);
                setPayButton(
                    <div key={selectedPrice + "" + quantity}>
                        <Elements stripe={stripePromise} options={{clientSecret: data.clientSecret, appearance: {}}}>
                            <ExpressCheckoutElement onConfirm={() => console.log("Express Checkout Success")}/>
                        </Elements>
                    </div>
                );
            } catch (error) {
                console.error("Error updating payment intent:", error);
            }
        };

        updatePaymentIntent();
    }, [quantity, selectedPrice]);

    useEffect(() => {
        const initializePaymentButton = async () => {
            try {
                let finalPrice = lang.includes("cs") ? (selectedPrice * quantity) : (selectedPrice * quantity) / 25;
                let currency = lang.includes("cs") ? "czk" : "eur";

                const response = await fetch("https://api.resellerheaven.eu/create-or-update-payment-intent2/"+ currency, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({totalAmount: finalPrice}), // Use the default price on load
                });
                const data = await response.json();
                setClientSecret(data.clientSecret);
                setPayButton(
                    <div key={price}>
                        <Elements stripe={stripePromise} options={{clientSecret: data.clientSecret, appearance}}>
                            <ExpressCheckoutElement onConfirm={() => console.log("Express Checkout Success")}/>
                        </Elements>
                    </div>
                );
            } catch (error) {
                console.error("Error initializing payment button:", error);
            }
        };

        initializePaymentButton();
    }, []); // Run once on mount


    const handleIncrement = () => {
        setQuantity((prev) => {
            if (prev < 10) {
                return prev + 1;
            }
            return prev;
        });
        setCartItems([{
            productTitle: title,
            quantity: quantity,
            variantTitle: Object.values(selectedOptions).map(option => ` | ${option} `).join('') + '|',
            imageUrl: selectedImage
        }]);
    };

    const handleDecrement = () => {
        setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
        setCartItems([{
            productTitle: title,
            quantity: quantity,
            variantTitle: Object.values(selectedOptions).map(option => ` | ${option} `).join('') + '|',
            imageUrl: selectedImage
        }]);
    };

    const handleChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (isNaN(value) || value <= 0) {
            setQuantity(1);
        } else {
            setQuantity(value);
        }

        setCartItems([{
            productTitle: title,
            quantity: quantity,
            variantTitle: Object.values(selectedOptions).map(option => ` | ${option} `).join('') + '|',
            imageUrl: selectedImage
        }]);
    };

    useEffect(() => {
        let finalPrice = lang.includes("cs") ? price : price / 25;
        let currency = lang.includes("cs") ? "czk" : "eur";

        fetch('https://api.whustle.com/product-pay/' + finalPrice + '/' + currency + '/ResellerHeaven', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({cartItems: cartItems})
        })
            .then(response => response.json())
            .then(data => {
                setUrl(data.url);
            });
    }, [price]); // This will trigger only when price changes


    const handleOptionSelect = (variantName, option) => {
        const updatedCartItems = [{
            productTitle: title,
            quantity: quantity,
            variantTitle: Object.values({
                ...selectedOptions,
                [variantName]: option.name,
            }).map(option => ` | ${option} `).join('') + '|',
            imageUrl: option.image || selectedImage,
        }];

        setCartItems(updatedCartItems);

        if (option.image) {
            setSelectedImage(option.image);
            setSelectedPreviewImage(option.image);
        }

        if (option.price && option.price !== selectedPrice) {
            let finalPrice = lang.includes("cs") ? option.price : option.price / 25;
            let currency = lang.includes("cs") ? "czk" : "eur";

            fetch('https://api.whustle.com/product-pay/' + finalPrice + '/' + currency + '/ResellerHeaven', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({cartItems: updatedCartItems}),
            })
                .then(response => response.json())
                .then(data => {
                    setUrl(data.url);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

            setSelectedPrice(option.price);
            setSelectedPriceFromatted(new Intl.NumberFormat('en-US', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(option.price));
        }

        if (option.stock) {
            setStock(option.stock);
        }

        setSelectedOptions((prevSelectedOptions) => ({
            ...prevSelectedOptions,
            [variantName]: option.name,
        }));
    };

    const [swipeDirection, setSwipeDirection] = useState(null); // Track swipe direction

    const handleSwipeLeft = () => {
        setSwipeDirection("left");
        setTimeout(() => {
            setSelectedPreviewImage(
                images[(images.findIndex((img) => img.url === selectedPreviewImage) + 1) % images.length].url
            );
        }, 300); // Delay to match the transition duration
    };

    const handleSwipeRight = () => {
        setSwipeDirection("right");
        setTimeout(() => {
            setSelectedPreviewImage(
                images[(images.findIndex((img) => img.url === selectedPreviewImage) - 1 + images.length) % images.length].url
            );
        }, 300); // Delay to match the transition duration
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const [cartItems, setCartItems] = useState([{
        productTitle: title,
        quantity: quantity, // Pokud používáte server-side templating, hodnotu interpolujte správně
        variantTitle: Object.values(selectedOptions).map(option => ` | ${option} `).join('') + '|', // Předpokládám, že `selectedOptions` je proměnná
        imageUrl: selectedImage // Podobně pro image URL
    }]);

    return (
        <div className={"w-full"}>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
            </Helmet>

            <div className="md:mt-0 mt-8 w-full md:w-3/5 mx-auto md:px-0 px-8">
                <div
                    className="flex-center mx-auto w-full md:w-fit pt-24 md:pt-32 grid grid-cols-1 md:grid-cols-2 text-white ">
                    <div className="flex justify-center px-2 md:w-5/6 md:justify-end">
                        {/* Image Section */}
                        <div className="justify-center sticky top-16 md:mt-0 mt-4 md:h-[550px] md:h-[600px]">
                            <div {...swipeHandlers} className="relative w-full md:h-[450px]">
                                <div className="relative w-full h-full overflow-hidden">
                                    <img
                                        src={selectedPreviewImage}
                                        alt={title}
                                        className="w-full h-full object-cover transition-all duration-300"
                                    />
                                </div>

                                {/* Slider for mobile */}
                                {images.length > 0 && (
                                    <div className="flex items-center justify-center space-x-4 mt-4 md:hidden">
                                        <button
                                            onClick={handleSwipeRight}
                                            className="text-white rounded-full px-4 py-2"
                                        >
                                            {"<"}
                                        </button>
                                        <span className="text-white text-xs">
                {Math.max(images.findIndex((img) => img.url === selectedPreviewImage) + 1, 1)}/{images.length}
            </span>
                                        <button
                                            onClick={handleSwipeLeft}
                                            className="text-white rounded-full px-4 py-2"
                                        >
                                            {">"}
                                        </button>
                                    </div>
                                )}
                            </div>


                            {/* Thumbnails for desktop */}
                            <div className="mt-4 hidden md:grid grid-cols-4 gap-1 w-[470px]">
                                {images.slice(0, 4).map((image, index) => (
                                    <img
                                        key={index}
                                        src={image.url}
                                        alt={`${title} Thumbnail ${index + 1}`}
                                        className={`h-12 w-12 md:h-20 md:w-20 object-cover border rounded cursor-pointer ${
                                            selectedPreviewImage === image.url ? "border-green-500" : "border-gray-300"
                                        }`}
                                        onClick={() => setSelectedPreviewImage(image.url)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className=" flex mx-auto justify-center flex-center  w-full md:w-5/6   px-2 md:mt-8">
                        {/* Product Information Section */}
                        <div className=" flex flex-col">
                            <h1 className="text-[30px] md:text-[40px]">{title}</h1>

                            {(rating && reviews.length > 0) ? <div className="inline-flex items-center -ml-1 space-x-2">
                                {[...Array(Math.round(averageRating))].map((_, index) => (
                                    <Star
                                        key={index}
                                        size={4}
                                        color={"text-green-400"}
                                    />
                                ))}
                                <p className=" mb-2 text-[13px] mt-3">{reviews.length} recenze</p>
                            </div> : <></>}

                            {stock > 0 && <div className="flex items-center tracking-wider space-x-2">
                                {/* Green circle */}
                                <div
                                    className={(selectedStock >= 4 ? "bg-green-500" : "bg-orange-500") + " w-2 h-2 border border-white rounded-full"}>
                                    <div
                                        className={(selectedStock >= 4 ? "bg-green-500" : "bg-orange-500") + " w-2 h-2 rounded-full blur-sm"}>

                                    </div>
                                </div>

                                {/* Text */}
                                <span className="text-gray-300 text-[10px] ">{selectedStock < 8 ?
                                    <span>{selectedStock} SKLADEM</span> : <span>SKLADEM</span>}</span>
                            </div>}

                            <div
                                className="tracking-wider mt-2 mb-2 text-[16px] md:text-[18px] inline-flex items-center space-x-2">
                                <p>{lang.includes("cs") ? selectedPriceFromatted + " Kč" : (selectedPrice / 25).toFixed(2) + " €"}</p>
                                {stock === 0 && <span
                                    className="bg-gray-100 text-gray-800 text-[8px] font-medium px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
                               Vyprodáno
                         </span>}
                            </div>
                            <p className="mb-1 text-gray-300 tracking-wider text-[10px]">Včetně daní</p>

                            {/* Variants */}
                            {stock > 0 &&
                                variants
                                    .slice() // Create a shallow copy to avoid mutating the original array
                                    .sort((a, b) => a.order - b.order) // Sort variants by order
                                    .map((variant) => (
                                        <div key={variant.variantName} className="mt-4">
                                            <p className="text-gray-300 mb-1 text-[13px]">{variant.variantName}</p>
                                            {variant.variantOptions.length > 5 ? (
                                                // Render dropdown if there are more than 5 options
                                                <select
                                                    value={selectedOptions[variant.variantName]}
                                                    onChange={(e) => {
                                                        const selectedOption = variant.variantOptions.find(
                                                            (option) => option.name === e.target.value
                                                        );
                                                        handleOptionSelect(variant.variantName, selectedOption);
                                                    }}
                                                    className="w-fit px-4 py-2 bg-black border border-gray-500 text-gray-300 rounded-full"
                                                >
                                                    {variant.variantOptions.map((option) => (
                                                        <option key={option.name} value={option.name}>
                                                            {option.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            ) : (
                                                // Render buttons for 5 or fewer options
                                                <div className="flex flex-wrap gap-2">
                                                    {variant.variantOptions.map((option) => (
                                                        <button
                                                            key={option.name}
                                                            onClick={() => handleOptionSelect(variant.variantName, option)}
                                                            className={`${
                                                                selectedOptions[variant.variantName] === option.name
                                                                    ? "bg-white text-black"
                                                                    : "bg-transparent text-white"
                                                            } border text-[14px] rounded-full py-2 px-5 m-1 cursor-pointer`}
                                                        >
                                                            {option.name}
                                                        </button>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}


                            {stock > 0 && <div>
                                <h1 className="mt-2 text-gray-300 text-[14px] md:text-[14px]"
                                >Množství</h1>
                                <div
                                    className="py-1 mt-2 flex items-center space-x-2 border border-white w-fit rounded-full">
                                    {/* Minus button */}
                                    <button
                                        onClick={handleDecrement}
                                        className="text-white rounded-full w-8 h-8 flex items-center justify-center "
                                    >
                                        -
                                    </button>

                                    {/* Input field */}
                                    <input
                                        type="number"
                                        value={quantity}
                                        onChange={handleChange}
                                        className="w-16 pl-1 text-center text-gray-300 bg-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />

                                    {/* Plus button */}
                                    <button
                                        onClick={handleIncrement}
                                        className="text-white rounded-full w-8 h-8 flex items-center justify-center "
                                        disabled={selectedStock <= quantity}
                                    >
                                        +
                                    </button>
                                </div>
                                {stock > 0 ? (
                                    <div className="grid grid-cols-1 w-full space-y-2 mt-4">
                                        <div>
                                            {clientSecret && (
                                                <>
                                                    {payButton}
                                                </>
                                            )}
                                        </div>
                                        <button
                                            onClick={handleAddToCart} // Trigger the drawer
                                            className="justify-start mt-3 md:mr-0 mr-2 text-center px-5 py-3 md:py-3 font-semibold md:font-bold bg-white text-black text-[15px] md:text-[14px] rounded-full hover:bg-gray-300 transition"
                                        >
                                            Přidat do košíku
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        <button
                                            className="mb-8 grid grid-cols-1 w-full space-y-2 mt-4 md:mr-0 mr-2 text-center px-5 md:px-20 py-3 md:py-3 font-semibold md:font-bold bg-gray-400 text-[15px] md:text-[16px] rounded-full transition disabled cursor-not-allowed opacity-75"
                                        >
                                            Vyprodáno
                                        </button>
                                    </div>
                                )}
                            </div>}


                            {/* Add to Cart and Buy Now Buttons */}
                            <div
                                className={`flex flex-col items-center justify-center ${stock > 0 ? "w-full" : "md:w-2/5 w-full"}`}>

                                {/* Order Tracking Component */}
                                {stock > 0 && (
                                    <div className="flex flex-col w-full justify-center">

                                        {/* Free Shipping Info Box */}
                                        {stock > 0 && (
                                            <div
                                                className="w-full border border-white p-3 text-center space-y-1.5 mt-8 mb-2">
                                                <h1 className="pt-0.5 font-bold text-[15px] md:text-[16px]">
                                                    Doprava zdarma do <ReactCountryFlag
                                                    countryCode="CZ"/> / <ReactCountryFlag countryCode="SK"/>
                                                </h1>
                                                <p className="pb-0.5 text-[12px]">
                                                    *Doprava je vypočítána podle dodacích lhůt dodavatele*
                                                </p>
                                            </div>
                                        )}

                                        <OrderTracking desc={desc}/>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>

                {/* Description Section */}
                <div className="flex flex-col items-center mt-8 md:mt-24">
                    <div className="mb-16 w-full">
                        {description.map((desc, index) => (
                            <p key={index}>{desc}</p>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    {rating && <div className={"mb-8 w-full"}>
                        <ReviewWidget product_handle={handle}/>
                    </div>}
                </div>

            </div>
        </div>
    );
}

export default ProductPage;
