import logo from "../imgs/logo.png";
import {useNavigate} from "react-router-dom";

function Footer() {
    const naigate = useNavigate();

    return (<div className="w-full">

        <hr className="border-[#262626] w-full"/>
        <footer className="md:w-3/5 w-full mx-auto md:mt-0 mt-8 bg-transparent">

            <div
                className="pt-8 pb-4 md:pt-12 md:pb-6 w-full  max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span className="text-[11px] text-gray-500 sm:text-center dark:text-gray-400">© 2024 <button
          onClick={() => naigate("/")}
          className="hover:underline">ResellerHeaven™</button>. All Rights Reserved.
    </span>
            </div>
        </footer>

    </div>)
}

export default Footer;