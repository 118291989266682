import './App.css';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import Main from "./Pages/Main";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ProductMain from "./Pages/ProductMain";
import Dotazy from "./Pages/Dotazy";
import Ukoncene from "./Pages/Ukoncene";
import {CardProvider} from "./Contexts/CardContext";
import {ProductProvider} from "./Contexts/ProductContext";
import {ReviewProvider} from "./Contexts/ReviewContext";
import Upload from "./Pages/Upload";
import {CartProvider, CartContext} from "./Contexts/CartContext";
import Cart from "./Pages/Cart";
import ProductCatalogue from "./Pages/ProductCatalogue";

import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {useContext, useEffect, useState} from "react";
import Download from "./Pages/Download";
import CDN from "./Pages/CDN";

function App() {
    const [clientSecret, setClientSecret] = useState(null);
    const stripePromise = loadStripe("pk_live_51Ozc6fFqQPKh8TUqUUqKnd5DnZdakxxj67nxBO8ImV1V5fzZWARjGMr0xOrnXYIJn1inum5JGRasaahjtat8j9gJ00z7sBXc7z");

    const {totalValue, cartItems} = useContext(CartContext);

    useEffect(() => {
        console.log("Cart Items in App:", cartItems); // Log cartItems to ensure updates
        console.log("Total Value in App:", totalValue); // Log totalValue to verify computation
    }, [cartItems, totalValue]);

    useEffect(() => {
        console.log("SECRET update: " + clientSecret);
    }, [clientSecret]);

    const appearance = {};


    return (
        <BrowserRouter>
            <div className={"bg-black flex flex-col min-h-screen"}>
                <div className="bg-black flex flex-col items-center x">
                    <CardProvider>
                        <ReviewProvider>
                            <ProductProvider>
                                <Navbar/>
                                <div className={"w-full"}>
                                    <Routes>
                                        <Route path="/" element={<Main/>}/>
                                        <Route path="/nejcastejsi-dotazy" element={<Dotazy/>}/>
                                        <Route path="/ukoncene-nabidky" element={<Ukoncene/>}/>
                                        <Route path="/upload" element={<Upload/>}/>
                                        <Route path="/cart" element={<Cart/>}/>
                                        <Route path="/products/:id" element={<ProductMain/>}/>
                                        <Route path="/collections/:name" element={<ProductCatalogue/>}/>
                                        <Route path="/download" element={<Download/>}/>
                                        <Route path="/cdn" element={<CDN/>}/>
                                        {/* Přidání trasy pro neexistující stránky */}
                                        <Route path="*" element={<Main/>}/>
                                    </Routes>
                                </div>
                                <Footer/>
                            </ProductProvider>
                        </ReviewProvider>
                    </CardProvider>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
